.nav {
    width: 100%;
    min-height: 55px;
    display: block;
    background-color: var(--secondary);
    position:relative;
}

.logoBox {
    position: absolute;
    left: 20px;
    top: 3px;
    height: 100%;
    display:flex;
    align-items:center;
    max-height: 50px;
}

.facebook {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #3b5998;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #555555;
    cursor: pointer;
}

.link {
    color: var(--primaryText) !important;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 0px;
}

/* hidden checkbox used for hamburger button tricks */
.hamburgerToggle {
    display: none;
}

.facebook a {
    color:white !important;
}

.link:hover {
    color: var(--accent) !important;
}

/* contains all nav links */
.linkContainer {
    height: 0px;
    transition: all .2s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* element that contains the three hamburger button bars */
.hamburgerIconContainer {
    float:right;
    display: block;
    padding: 20px;
}

/* the middle hamburger button bar */
.hamburgerIcon {
    background-color: white;
    display:block;
    width: 18px;
    height: 2px;
    margin-bottom:2px;
    margin-top:2px;
    transition: all .2s ease-out;
}

/* top and bottom hamburger button bars */
.hamburgerIconContainer:before, .hamburgerIconContainer:after {
    background-color: white;
    content: '';
    display: block;
    position:relative;
    width: 18px;
    height: 2px;
    transition: all .2s ease-out;
}


/* highlight the hamburger when hovering over it */
.hamburgerIconContainer:hover  .hamburgerIcon,
.hamburgerIconContainer:hover:before,
.hamburgerIconContainer:hover:after {
    background-color: var(--accent) !important;
}

/*
when clicking on the hamburger button and thus checking the
hidden checkbox, grab the link container (positioned after the
hidden checkbox, hence the ~), and expand it
 */
.hamburgerToggle:checked ~ .linkContainer {
    height: 240px !important;
    clear:both;
}

/*Next three declarations convert the hamburger into an x when it is clicked on*/
.hamburgerToggle:checked ~ .hamburgerIconContainer:before{
    transform: translate(0px, 1px) rotate(45deg);
}

.hamburgerToggle:checked ~ .hamburgerIconContainer:after{
    transform: translate(0px, -1px) rotate(-45deg);
}

.hamburgerToggle:checked ~ .hamburgerIconContainer .hamburgerIcon{
   display:none;
}

@media only screen and (max-width: 768px) {
    /*make sure links disappear before link container slides up*/
    .hamburgerToggle:not(:checked) ~ .linkContainer .link {
        opacity: 0;
    }

    /*make sure facebook disappears before link container slides up*/
    .hamburgerToggle:not(:checked) ~ .linkContainer .facebook {
        opacity: 0;
    }
}

@media only screen and (min-width:769px) {
    .hamburgerIconContainer {
        display:none;
    }

    .nav {
        display: flex;
        justify-content: center;
    }

    .justifyRight{
        justify-content: flex-end;
    }

    .linkContainer {
        height: initial;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .link {
        color:white !important;
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 0px;
    }
}
