@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  overflow: scroll;
  height: 100vh;
  background-color: #2E4053;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
  --secondary: #080808;
  --primary: #43464B;
  --accent: #3398DA;
  --primaryText: white;
  --mainContentPad: 20px;
  --negativeContentPad: -20px;
  color: white;
  color: var(--primaryText);
}

.bgSecondary{
  background-color: #080808;
  background-color: var(--secondary);
}

.primaryText {
  color: white;
  color: var(--primaryText);
}

.Navbar_nav__33uk1 {
    width: 100%;
    min-height: 55px;
    display: block;
    background-color: var(--secondary);
    position:relative;
}

.Navbar_logoBox__2DjNZ {
    position: absolute;
    left: 20px;
    top: 3px;
    height: 100%;
    display:flex;
    align-items:center;
    max-height: 50px;
}

.Navbar_facebook__1TKwn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #3b5998;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #555555;
    cursor: pointer;
}

.Navbar_link__3dwSW {
    color: var(--primaryText) !important;
    margin-bottom: 10px;
    cursor: pointer;
    margin-right: 0px;
}

/* hidden checkbox used for hamburger button tricks */
.Navbar_hamburgerToggle__175LP {
    display: none;
}

.Navbar_facebook__1TKwn a {
    color:white !important;
}

.Navbar_link__3dwSW:hover {
    color: var(--accent) !important;
}

/* contains all nav links */
.Navbar_linkContainer__5S-zL {
    height: 0px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

/* element that contains the three hamburger button bars */
.Navbar_hamburgerIconContainer__30rKU {
    float:right;
    display: block;
    padding: 20px;
}

/* the middle hamburger button bar */
.Navbar_hamburgerIcon__1ykyU {
    background-color: white;
    display:block;
    width: 18px;
    height: 2px;
    margin-bottom:2px;
    margin-top:2px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

/* top and bottom hamburger button bars */
.Navbar_hamburgerIconContainer__30rKU:before, .Navbar_hamburgerIconContainer__30rKU:after {
    background-color: white;
    content: '';
    display: block;
    position:relative;
    width: 18px;
    height: 2px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}


/* highlight the hamburger when hovering over it */
.Navbar_hamburgerIconContainer__30rKU:hover  .Navbar_hamburgerIcon__1ykyU,
.Navbar_hamburgerIconContainer__30rKU:hover:before,
.Navbar_hamburgerIconContainer__30rKU:hover:after {
    background-color: var(--accent) !important;
}

/*
when clicking on the hamburger button and thus checking the
hidden checkbox, grab the link container (positioned after the
hidden checkbox, hence the ~), and expand it
 */
.Navbar_hamburgerToggle__175LP:checked ~ .Navbar_linkContainer__5S-zL {
    height: 240px !important;
    clear:both;
}

/*Next three declarations convert the hamburger into an x when it is clicked on*/
.Navbar_hamburgerToggle__175LP:checked ~ .Navbar_hamburgerIconContainer__30rKU:before{
    -webkit-transform: translate(0px, 1px) rotate(45deg);
            transform: translate(0px, 1px) rotate(45deg);
}

.Navbar_hamburgerToggle__175LP:checked ~ .Navbar_hamburgerIconContainer__30rKU:after{
    -webkit-transform: translate(0px, -1px) rotate(-45deg);
            transform: translate(0px, -1px) rotate(-45deg);
}

.Navbar_hamburgerToggle__175LP:checked ~ .Navbar_hamburgerIconContainer__30rKU .Navbar_hamburgerIcon__1ykyU{
   display:none;
}

@media only screen and (max-width: 768px) {
    /*make sure links disappear before link container slides up*/
    .Navbar_hamburgerToggle__175LP:not(:checked) ~ .Navbar_linkContainer__5S-zL .Navbar_link__3dwSW {
        opacity: 0;
    }

    /*make sure facebook disappears before link container slides up*/
    .Navbar_hamburgerToggle__175LP:not(:checked) ~ .Navbar_linkContainer__5S-zL .Navbar_facebook__1TKwn {
        opacity: 0;
    }
}

@media only screen and (min-width:769px) {
    .Navbar_hamburgerIconContainer__30rKU {
        display:none;
    }

    .Navbar_nav__33uk1 {
        display: flex;
        justify-content: center;
    }

    .Navbar_justifyRight__3GMD7{
        justify-content: flex-end;
    }

    .Navbar_linkContainer__5S-zL {
        height: auto;
        height: initial;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .Navbar_link__3dwSW {
        color:white !important;
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 0px;
    }
}

.SimpleImage_imageContainer__2_ii8 {
    overflow: hidden;
    /*in case an absolutely positioned backdrop is desired over image.
     need this to calculate backdrop height/width*/
    position: relative;
}

/*A simple image is an image with no enforced aspect ratio*/
.SimpleImage_simpleImageWidthScale__2w2Y- {
    /*will always take full container width.
    The height will be automatically scaled to keep
    the picture's original aspect ratio. The height
    overflow will be hidden by the imageContainer*/
    width: 100%;
}

.SimpleImage_simpleImageHeightScale__9Tbxk {
    /*Non-default scaling option. Takes full container height and
    automatically adjusts width to maintain original aspect ratio*/
    height: 100%;
}

.SimpleImage_contentOverlay__KIs-u {
    position: absolute;
    height: 100%;
    width:100%;
}
.Backdrop_backdrop__3F4pl {
    /*opacity: .7;*/
    /*background-color: #555555;*/
    background-color: rgba(85, 85, 85, .7);
    height:100%;
    position: absolute;
    /* this is applied on a div; however, position absolute causes
    the width to be as wide as its content initially*/
    width: 100%;
}
.MainContent_mainContent__1AaVf {
    display: block;
    min-height: 80%;
    background-color: var(--primary);
    width: 80%;
    max-width: 1330px;
    margin: 0px auto;
    padding: var(--mainContentPad);
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
}

.Home_homeContent__239hO {
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.Home_banner__2NyKm {
    margin-top: var(--negativeContentPad);
    margin-left: var(--negativeContentPad);
    margin-right: var(--negativeContentPad);
    background-color: var(--accent);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.7);
}

.Home_homeLogoBox__cLrHE {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Home_store__20sFl {
    padding-top: 15px;
}

.Home_disclaimer__1iskE{
    text-align:center;
    background-color: var(--accent);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.7);
}

.Home_noRefundText__3whJO{
    font-size: 12px;
    font-style: italic;
}

@media only screen and (min-width:769px) {
    .Home_disclaimer__1iskE {
        font-size: 20px;
    }
}

.LicenceBox_licenseText__2qyUE {
    text-align: center;
    font-size: 12pt;
    line-height: 25pt;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 2%;
}

.LicenceBox_licenseType__1Bpda {
    font-size: 40px;
    line-height: 65px;
}

.LicenceBox_licencePrice__3A15S {
    font-size: 45px;
    line-height: 75px;
}

.LicenceBox_box__154NH{
    background-color: var(--accent);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.7);
    box-sizing: border-box;
}

.Organizer_organizer__daI8O * {
    margin-bottom: 2%;
}

.Organizer_organizer__daI8O {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Organizer_justifyEven__dfGn9 {
    justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {

    .Organizer_organizer__daI8O {
        display: block;
    }

    .Organizer_organizer__daI8O > * {
        max-width: none !important;
        max-width: initial !important;
    }
}

.AspectRatio_ratio16x9__3J9wy {
    position: relative;
    padding-bottom: 56.25%;
}


.AspectRatio_ratio4x3__3KKwf {
    position: relative;
    padding-bottom: 75%;
}


.AspectRatio_ratio3x2__3hJAw {
    position: relative;
    padding-bottom: 66.66%;
}

.AspectRatio_ratio8x5__3CEuC {
    position: relative;
    padding-bottom: 62.5%;
}

.AspectRatio_ratioItem__191Sn {
    position: absolute;
    top: 0;
    left: 0;
    /*even though parent does not have set height, we can use relative height due to absolute positioning*/
    height: 100%;
    width: 100%;
}

.SimpleCard_card__1kQRk {
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: box-shadow .3s;
    transition: box-shadow .3s;
    /*This is overriden because this is a flex child most of the time.
     'block' only kicks in during mobile view when the Organizer
     no longer displays as flex
    */
    display: block;
}

.SimpleCard_card__1kQRk:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.SimpleCard_cardImage__UHycT {
  width: 100%;
}

.SimpleCard_imagePortion__3HAQK {
    height: 80%;
}

.SimpleCard_cardTitle__38TdD {
    text-align: center;
    margin: 0px;
}

.PlaceholderCard_placeHolderCard__hpXg9 {
    display: none;
}

@media only screen and (min-width: 769px) {
    .PlaceholderCard_placeHolderCard__hpXg9 {
        display: inline;
        display: initial;
        height: 0px;
    }
}
.Loader_loader__3QxMA {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: Loader_spin__3jdaw 2s linear infinite;
            animation: Loader_spin__3jdaw 2s linear infinite;
}

@-webkit-keyframes Loader_spin__3jdaw {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Loader_spin__3jdaw {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Loader_loaderWrapper__5STh5 {
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.BeatStore_storeContainer__1vnMI {
    position: relative;
}

.BeatStore_storeShadow__1_Zwk {
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.7);
}

.BeatStore_storeHeight__2Iljm {
    height: 510px;
}

.BeatStore_hide__KZWLb {
    display: none;
}

.Divider_divider__2RMe3 {
    height: 50%;
    border-bottom: 1px solid var(--accent);
}

.Footer_footer__GwAR6 {
    height: 300px;
    text-align: center;
    background-color: var(--secondary);
    padding-top: 4%;
    padding-bottom: 4%;
    box-sizing: border-box;
    color: var(--primaryText);
}

.Footer_footerLargeText__1pWsw {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}
.SMIcon_smIcon__2Ursd {
    background-color: var(--secondary);
    box-sizing: border-box;
    border-radius: 50%;
    color: var(--primaryText);
    text-decoration: none;
}

.SMIcon_smIcon__2Ursd:hover {
    color: var(--accent);
    cursor: pointer;
}



.SMIconTray_smIconTray__2qz7T > a {
    margin-right: 20px;
}
