.organizer * {
    margin-bottom: 2%;
}

.organizer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.justifyEven {
    justify-content: space-evenly;
}

@media only screen and (max-width: 768px) {

    .organizer {
        display: block;
    }

    .organizer > * {
        max-width: initial !important;
    }
}
