.homeContent {
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.banner {
    margin-top: var(--negativeContentPad);
    margin-left: var(--negativeContentPad);
    margin-right: var(--negativeContentPad);
    background-color: var(--accent);
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.7);
}

.homeLogoBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.store {
    padding-top: 15px;
}

.disclaimer{
    text-align:center;
    background-color: var(--accent);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.7);
}

.noRefundText{
    font-size: 12px;
    font-style: italic;
}

@media only screen and (min-width:769px) {
    .disclaimer {
        font-size: 20px;
    }
}
