.licenseText {
    text-align: center;
    font-size: 12pt;
    line-height: 25pt;
    border-bottom: 1px solid var(--primary);
    padding-bottom: 2%;
}

.licenseType {
    font-size: 40px;
    line-height: 65px;
}

.licencePrice {
    font-size: 45px;
    line-height: 75px;
}

.box{
    background-color: var(--accent);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.7);
    box-sizing: border-box;
}
