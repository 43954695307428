.footer {
    height: 300px;
    text-align: center;
    background-color: var(--secondary);
    padding-top: 4%;
    padding-bottom: 4%;
    box-sizing: border-box;
    color: var(--primaryText);
}

.footerLargeText {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 20px;
}