.ratio16x9 {
    position: relative;
    padding-bottom: 56.25%;
}


.ratio4x3 {
    position: relative;
    padding-bottom: 75%;
}


.ratio3x2 {
    position: relative;
    padding-bottom: 66.66%;
}

.ratio8x5 {
    position: relative;
    padding-bottom: 62.5%;
}

.ratioItem {
    position: absolute;
    top: 0;
    left: 0;
    /*even though parent does not have set height, we can use relative height due to absolute positioning*/
    height: 100%;
    width: 100%;
}
