.storeContainer {
    position: relative;
}

.storeShadow {
    box-shadow: 0 2px 8px 2px rgba(0,0,0,0.7);
}

.storeHeight {
    height: 510px;
}

.hide {
    display: none;
}
