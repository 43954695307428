.mainContent {
    display: block;
    min-height: 80%;
    background-color: var(--primary);
    width: 80%;
    max-width: 1330px;
    margin: 0px auto;
    padding: var(--mainContentPad);
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.7);
}
