.App {
  overflow: scroll;
  height: 100vh;
  background-color: #2E4053;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --secondary: #080808;
  --primary: #43464B;
  --accent: #3398DA;
  --primaryText: white;
  --mainContentPad: 20px;
  --negativeContentPad: -20px;
  color: var(--primaryText);
}

.bgSecondary{
  background-color: var(--secondary);
}

.primaryText {
  color: var(--primaryText);
}
