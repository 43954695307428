.imageContainer {
    overflow: hidden;
    /*in case an absolutely positioned backdrop is desired over image.
     need this to calculate backdrop height/width*/
    position: relative;
}

/*A simple image is an image with no enforced aspect ratio*/
.simpleImageWidthScale {
    /*will always take full container width.
    The height will be automatically scaled to keep
    the picture's original aspect ratio. The height
    overflow will be hidden by the imageContainer*/
    width: 100%;
}

.simpleImageHeightScale {
    /*Non-default scaling option. Takes full container height and
    automatically adjusts width to maintain original aspect ratio*/
    height: 100%;
}

.contentOverlay {
    position: absolute;
    height: 100%;
    width:100%;
}