.card {
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: box-shadow .3s;
    /*This is overriden because this is a flex child most of the time.
     'block' only kicks in during mobile view when the Organizer
     no longer displays as flex
    */
    display: block;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.cardImage {
  width: 100%;
}

.imagePortion {
    height: 80%;
}

.cardTitle {
    text-align: center;
    margin: 0px;
}
