.smIcon {
    background-color: var(--secondary);
    box-sizing: border-box;
    border-radius: 50%;
    color: var(--primaryText);
    text-decoration: none;
}

.smIcon:hover {
    color: var(--accent);
    cursor: pointer;
}
